.designField .ant-form-item{
    margin-bottom: 0;
}

.ant-pro-table p{
    font-size: 13px;
}

.center-table table{
    text-align: center;
}
.center-table .ant-table-wrapper .ant-table-thead >tr>th{
    text-align: center;
}

.ant-pro-card-body{
    padding: 0;
}
.ant-pro-table .ant-pro-table-search{
    padding: 0;
    margin-bottom: 0;
}
.ant-input-number-input{
    max-width: 100px!important;
}
.ant-form-item-label{

}
.tabModal .ant-modal-header{
    padding: 0;
    border: none;
}
.tabModal .ant-tabs-top > .ant-tabs-nav{
    margin-bottom: 0;
}

.inline-group .ant-form-item-control-input-content{
    display: flex;
    flex-direction: row;
    align-items: center;
}

.inline-group .ant-form-item-control-input-content .space{
    margin: 0 5px;
}
.inline-group .ant-form-item-control-input-content .ant-form-item{
    margin-bottom: 0;
}


.ant-menu-item{
    padding-left: 12px !important;
}
.sidebar-menu .ant-menu-submenu-title{
    padding-left: 12px !important;
}
.sidebar-menu .ant-menu-sub .ant-menu-item{
    padding-left: 47px !important;
}
.ant-layout-sider-children{
    position: relative;
    z-index: 100;
    height: 100%;
}
.ant-layout-sider-collapsed{
    flex: 0 0 56px !important;
    max-width: 56px !important;
    min-width: 56px !important;
    width: 56px !important;
}

.ant-pro-steps-form-container{
    width: auto !important;
    max-width: 1200px !important;
}

.ant-pro-basicLayout-content{
    margin: 0!important;
}
.ant-pro-table .ant-card-body{
    padding: 0;
}

.ant-pro-footer-bar-left{
    display: none;
}
.ant-pro-footer-bar-right{
    width: 100%;
    padding: 10px 0;
    display: flex;
    justify-content: center;
    flex-direction: row;
}
.editorBtnWrap .ant-btn-primary:hover{
    background: #1890ff !important;
    border-color: #1890ff !important;
}
.editorBtnWrap .ant-btn-primary:focus{
    background: #1890ff !important;
    border-color: #1890ff !important;
}

@media screen and(-ms-high-contrast: active), (-ms-high-contrast: none) {
    body .ant-design-pro > .ant-layout {
        min-height: 100vh;
    }
}

.data-table .ant-pro-card-body{
    padding: 0;
}
.searchForm .ant-row .ant-col:last-child{
    text-align: left !important;
    margin-inline-start: 0;
    padding-left: 0 !important;
}
.searchForm .ant-pro-form-group{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
}
.searchForm .ant-pro-form-group>div:last-child{
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 48px;
}
.searchForm .ant-space-compact{
    width: 100%;
}
.no-form-item-margin .ant-form-item{
    margin-bottom: 0!important;
}
.data-table .singleLineForm .ant-form-item-label label{
    min-width: 0 !important;
}
.userSearchForm .ant-form-item{
    margin-bottom: 10px;
}
.userSearchForm .ant-pro-form-group .ant-form-item{
    margin-bottom: 0;
}

.searchForm .ant-pro-form-group .ant-pro-form-group-title{
    margin-block-end: 0;
}

.searchForm .ant-pro-query-filter-actions .ant-space {
    display: flex;
}
.searchForm .ant-pro-query-filter-actions:first-child {
    flex: 1;
}
.searchForm .ant-pro-query-filter-collapse-button{
    font-size: 14px;
}
.data-table .ant-pro-table-list-toolbar-inline-menu-item-active{
    color: #1890ff;
}
.data-table .ant-form-item-label label{
    min-width: 70px !important;
    justify-content: flex-end;
}

.data-table .ant-row .ant-col:last-child .ant-form-item-label label{
    width: 0;
    min-width: 0!important;
    margin-left: 12px;
}

.ant-pro-card-body{
    padding-inline: 0 !important;
}
.ant-modal-body{
    overflow-x: hidden !important;
}

.ant-modal .ant-modal-header{
    margin-bottom: 20px;
}

.ant-table-cell .ant-table-small{
    border-top: 1px solid #efefef;
}
.ant-table-cell .ant-form-item{
    margin-bottom: 5px;
    margin-top: 5px;
}

.mute-upload-wrap .ant-upload-list{
    display: none;
}
.fuben-modal-form .ant-space{
    display: block !important;
}

.ant-pro-form-group-title{
    border-bottom: 1px solid #f2f2f2;
    padding-bottom: 10px;
}
.ant-form-vertical .ant-form-item-label{
    font-weight: 500;
}

.no-style-input .ant-form-item{
    margin-bottom: 0;
}

.common-form .ant-form-item-label{
    text-align: right;
    width: 100px !important;
}
.common-form .ant-form-item-control{
    justify-content: flex-start;
}
.common-form > .ant-space{
    margin-left: 100px;
}

.ant-pro-table-search-light-filter.searchForm, .ant-pro-table-search-light-filter .searchForm{
    border: none;
    background: none !important;
    border-radius: 0;
}
.ant-pro-table-search-light-filter.searchForm{

}
.ant-pro-form-light-filter-item{
    border: 1px solid #e2e2e2;
    border-radius: 6px;
}
.ant-pro-card-statistic-layout-vertical{
    text-align: center;
}


.small-tag{
    line-height: 16px;
    font-size: 10px;
}
